:root {
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
}

.__akia-chat-icon-wrapper {
  bottom: 170px!important;
  right: 30px!important;
}

.grecaptcha-badge {
  visibility: hidden;
}

html {
  box-sizing: border-box;

  font-size: 15px;
  line-height: 2.3;
  letter-spacing: 1px;
  font-family:'Gill Sans W04 Book', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #494a4c;
}

body {
  margin: 0;
  width: 100%;
}

#root {
  overflow-x: hidden;
}

* {
  box-sizing: inherit;
}

/* ========================= Component Styles ========================= */

h1, .h1 {
  font-size: 1.875rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.3125rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family:'Gill Sans W04 Book';
  line-height: normal;
  margin: 0
}

.text-accent {
  font-weight: 600;
  line-height: normal;
  font-family:'Gill Sans W01 Heavy';
}

.text-serif {
  font-family: "Bodoni MT W01 Book", serif;
}

.text-responsive {
  font-size: clamp(100%, 3vw, 150%);
}

/* Use span or div if you don't need this spacing */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6,
* + p {
  margin-top: 1em;
}

.page-title {
  position: absolute;
  transform: translate(0, -50%);
  top: 70%;
  right: 15%;
}

.break-out-banner {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

@media screen and (min-width: 1024px) {
  .hide-above-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .hide-on-lg {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hide-above-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-on-md {
    display: none;
  }

  .page-title {
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    right: 0;
    width: fit-content;
    text-align: center;
  }
}

@media screen and (max-width: 640px) {
  .hide-on-sm {
    display: none;
  }
}

.skip-to-content-link {
    border: 1px solid black;
    height: 41px;
    left: 50%;
    padding: 3px 10px 10px 10px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 11;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

*:focus{
  outline: 2px solid;
}