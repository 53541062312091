.favorite__tab {
    position: fixed;
    bottom: 165px;
    right: 5px;
    width: 63px;
    height: 63px;
}

.favorite__panel-logo {
    transform: translate(0, -50%);
}

.favorite__panel {
    height: 66.67vh;
}

@media screen and (max-width: 768px) {
    .favorite__panel {
        height: 80vh;
    }
}